import React from "react";
import {useMemo} from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {Box, Button} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {mkConfig, generateCsv, download} from 'export-to-csv';

export const hiddenColumns = [
    "surveyName", "surveyPurpose", "surveyParticipants", "surveyPeriod", "surveyContact",
    "received_checkin_date", "channel_profile", "name", "code", "brand", "welcome", "sickLeaveExperienceExplanation",
    "personalInformation", "understandingOfStandardsAndRegulations",
    "understandingOfGeneralWorkEnvironment", "other", "complaintExplanation"];

const CheckinReportDataTable = ({data}) => {
    const columns = useMemo(
        () => data.columns
            .filter(column => !hiddenColumns.includes(column))
            .sort()
            .map(column => ({
                accessorKey: column.key,
                header: column.label,
            })),
        [],
    );

    let date = new Date();
    let headers = data.columns
        .filter(column => !hiddenColumns.includes(column))
        .map(column => ({
            key: column.key,
            displayLabel: column.label,
        }));

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: false,
        columnHeaders: headers,
        filename: `Report_${data.id}_${date.toISOString().slice(0, 10)}_${date.toLocaleTimeString("de-DE").slice(0, 5)}`,
    });

    const handleExportRows = (rows) => {
        const rowData = rows.map(row => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable({
        columns,
        data: data.data,
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon/>}
                >
                    Download CSV
                </Button>


            </Box>
        ),
    });

    return <MaterialReactTable table={table}/>;
};

export default CheckinReportDataTable;
