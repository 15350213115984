import React, {useEffect, useState} from "react";

import transitions from "../../views/Transitions.css";
import StepWizard from 'react-step-wizard';
import Nav from "./Nav";
import Button from "@mui/material/Button";
import {CardMedia, Link, Stack, TextField} from "@mui/material";
import UploadFile from "../common/file/UploadFile";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import reactStringReplace from 'react-string-replace';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChatIcon from '@mui/icons-material/Chat';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import QuizIcon from '@mui/icons-material/Quiz';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CopyToClipboardButton from "./CopyToClipboardButton";
import worker_chat from "../../assets/home/worker chat.png";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import Speech from "./Speech";
import Slider from '@mui/material/Slider';
import {useLazyOpenPageQuery} from "../../app/apiSlice";
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import {Box} from "@mui/system";

const ComplaintWizard = ({
                             workerChat = worker_chat,
                             brand,
                             steps,
                             updateRequest,
                             name,
                             useUpdateMutation,
                             useGetCodeQuery,
                             useCreateMutation,
                             translation,
                             theme, speech
                         }) => {

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        },
    });

    const [create] = useCreateMutation();
    const [update] = useUpdateMutation();
    const {data: result} = useGetCodeQuery();
    const [triggerOpenPage] = useLazyOpenPageQuery();

    useEffect(() => {
        triggerOpenPage(name);
    }, []);

    const fetchDataa = async (props) => {
        try {
            await create({...props.form, code: props.code, brand: props.brand, name}).unwrap();
        } catch (error) {
            console.error(error);
        }
    };

    const updateForm = (key, value) => {
        const {form} = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = async (state, code) => {
        if (updateRequest) await updateRequest(state.form, code);
        else {
            try {
                await update({...state.form, code: code, name: name, brand: brand}).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    if (!result) return null;

    return (
        <Card variant="outlined" sx={{
            height: "100%", p: 1, boxShadow: "0px 4px 4px 0px #00000040",
            borderWidth: "4px", borderColor: theme.palette.primary.light
        }}>
            <StepWizard
                onStepChange={() => onStepChange(state, result.code)}
                initialStep={1}
                nav={<Nav steps={steps} translation={translation}/>}
                instance={setInstance}
            >
                {steps.map(step => <Step stepName={step.stepName} key={step.stepName} step={step} form={state.form}
                                         update={updateForm} code={result.code} beforeStep={"info"}
                                         translation={translation} speech={speech}
                                         workerChat={workerChat} brand={brand} fetchData={fetchDataa}/>)}

            </StepWizard>
        </Card>
    );
};

export default ComplaintWizard;


export const Step = (props) => {

    const [value, setValue] = useState(props.step.scale ? (props.step.scale.startAtOne ? 1 : 0) : "");

    useEffect(() => {
        if (!!props.form[props.stepName])
            setValue(props.form[props.stepName])
    }, [props.form[props.stepName]]);

    const update = (value) => {
        if (props.step.scale)
            props.update(props.step.scale.name, value);
        else if (props.step.field && (props.step.field.type === "array" || props.step.field.type === "files" || props.step.field.type === "phoneNumbersArray"))
            props.update(props.step.field.name, [...(props.form[props.step.field.name] ? props.form[props.step.field.name] : []), value,]);
        else
            props.update(props.step.field.name, value);
    };

    const numberOfButtons = (props.step.buttons ? props.step.buttons.length : 0);

    function justifyButtons() {
        if (props.step.multipleChoice || numberOfButtons > 2) return "flex-start";
        return (numberOfButtons <= 1) ? "flex-end" : "space-between";
    }

    function flexDirection() {
        if (props.step.multipleChoice || numberOfButtons > 2) return "column";
        return "row";
    }

    function startIcon(icon) {
        if (icon === "CheckCircleIcon") return <CheckCircleIcon/>
        if (icon === "SkipNextIcon") return <SkipNextIcon/>
        if (icon === "SendIcon") return <SendIcon/>
        if (icon === "QuestionAnswerIcon") return <QuestionAnswerIcon/>
        if (icon === "ChatIcon") return <ChatIcon/>
        if (icon === "YouTubeIcon") return <YouTubeIcon/>
        if (icon === "VerifiedUserIcon") return <VerifiedUserIcon/>
        if (icon === "QuizIcon") return <QuizIcon/>
        if (icon === "LocalPhoneIcon") return <LocalPhoneIcon/>
        if (icon === "EmailIcon") return <EmailIcon/>
        if (icon === "InfoOutlinedIcon") return <InfoOutlinedIcon/>
        if (icon === "CheckIcon") return <CheckIcon/>
        if (icon === "FastRewindIcon") return <FastRewindIcon/>
        if (icon === "DoNotDisturbOnIcon") return <DoNotDisturbOnIcon/>
        if (icon === "PhonelinkLockIcon") return <PhonelinkLockIcon/>
        if (icon === "DoNotDisturbAltIcon") return <DoNotDisturbAltIcon/>
        if (icon === "GppMaybeIcon") return <GppMaybeIcon/>
        if (icon === "ArrowRightAltIcon") return <ArrowRightAltIcon/>
        return undefined;
    }

    const {t, i18n} = useTranslation(props.translation);

    function getCountry() {
        if (i18n.language && i18n.language.length === 5) {
            const parts = i18n.language.split("-");
            if (parts && parts.length === 2) return parts[1].toLowerCase();
        }
        if (i18n.language && i18n.language.length === 2) {
            let locale = i18n.language.toLowerCase();
            if (locale === "en") return "de";
            return locale;
        }
        return "vn";
    }

    function speech(text) {
        if (text && props.speech)
            return <Speech id={uuidv4()} text={text} rate={4} lang={i18n.language} pitch={5} volume={9} style={{}}/>;
    }

    function getInputProps(props) {
        if (props.step.field.type === "number") {
            return {autoComplete: 'off', min: 1, inputMode: 'numeric', pattern: '[0-9]*'};
        }
        return {autoComplete: 'off'};
    }

    function MultipleSelect({props}) {
        const [selection, setSelection] = useState(value || []);

        function disabled(button) {
            if (selection.length > 0 && button.excludesOthers && !selection.includes(button.update.value)) return true;
            return props.step.buttons.filter(button => selection.includes(button.update.value))
                .filter(button => button.excludesOthers).length > 0 && !selection.includes(button.update.value);
        }

        return <>
            {props.step.buttons.map(button =>
                <Stack key={button.label} justifyContent="start" direction="row" ml={1} mt={1}>
                    <Button key={button.label}
                            disabled={disabled(button)}
                            variant={selection.includes(button.update.value) ? "outlined" : "contained"}
                            startIcon={startIcon(button.icon)}
                            onClick={() => {
                                if (selection.includes(button.update.value)) {
                                    setSelection(selection.filter(item => item !== button.update.value));
                                } else {
                                    setSelection([...selection, button.update.value]);
                                }

                            }}>{t(button.label)}
                    </Button>
                    {speech(t(button.label))}
                </Stack>)}
            <Grid item xs={12} sm={12} container direction="row" justifyContent="end" alignItems="flex-end">
                <Stack justifyContent="start" direction="row">
                    <Button variant="contained" disabled={selection.length === 0} color="secondary"
                            startIcon={<SendIcon/>}
                            onClick={() => {
                                props.update(props.step.multipleSelect.name, selection);
                                const gotos = props.step.buttons.filter(b => selection.includes(b.update.value))
                                    .filter(b => b.goto).map(b => b.goto);
                                if (gotos.length > 0) props.goToNamedStep(gotos[0]);
                                else props.goToNamedStep(props.step.multipleSelect.submit.goto);
                            }}>
                        {t(props.step.multipleSelect.submit.label)}
                    </Button>{speech(t(props.step.multipleSelect.submit.label))}
                </Stack>
            </Grid>
        </>;
    }

    function SingleSelect({props}) {
        const [selection, setSelection] = useState(props.step.buttons.find(button => button.update?.value === value));
        return <>
            {props.step.buttons.map(button =>
                <Stack key={button.label} justifyContent="start" direction="row" ml={1} mt={1}>
                    <Button key={button.label}
                            variant={selection === button ? "outlined" : "contained"}
                            startIcon={startIcon(button.icon)}
                            onClick={() => {
                                setSelection(button);
                            }}>{t(button.label)}
                    </Button>
                    {speech(t(button.label))}
                </Stack>)}
            <Grid item xs={12} sm={12} container direction="row" justifyContent="end" alignItems="flex-end">
                <Stack justifyContent="start" direction="row">
                    <Button variant="contained" disabled={!selection} color="secondary"
                            startIcon={<SendIcon/>}
                            onClick={() => {
                                if (selection.update) props.update(selection.update.field, selection.update.value);
                                props.goToNamedStep(selection.goto);
                            }}>
                        {t('send')}
                    </Button>{speech(t('send'))}
                </Stack>
            </Grid>
        </>;
    }

    function textToSee() {
        if (props.step.text) {
            let text = reactStringReplace(t(props.step.text), '##CODE##', () => (
                <CopyToClipboardButton text={props.code}
                                       key={props.step.stepName + "_" + props.code + "_" + Math.random() * 10}
                                       toast={t(props.step.toast)}/>
            ))
            text = reactStringReplace(text, '\n',
                () => (<br key={"br_" + props.step.stepName}></br>))
            return text;
        }
        return null;
    }

    function textToHear() {
        return reactStringReplace(t(props.step.text), '##CODE##', () => props.code);
    }

    return <>
        <CardHeader
            avatar={
                props.currentStep === 1 && <img src={props.workerChat} alt="worker_chat" width="120"/>
            }
            title={<Typography variant="h6">{t(props.step.title)}{speech(t(props.step.title))}</Typography>}
        />
        {props.step.gif &&
            <CardMedia
                component="img"
                image={t(props.step.gif.url)}
                alt={t(props.step.gif.alt)}
            />}
        <CardContent>
            {props.step.text && <>{textToSee()}{speech(textToHear())}</>}

            {props.step.scale && <>
                <Box ml={2} mr={5}>
                    <Slider sx={{pr: 2,}}
                            aria-label={props.step.scale.name}
                            defaultValue={props.step.scale.startAtOne ? 1 : 0}
                            value={value}
                            getAriaValueText={function (value) {
                                return t(props.step.scale.steps[value - (props.step.scale.startAtOne ? 1 : 0)]);
                            }}
                            valueLabelFormat={function (value) {
                                return t(props.step.scale.steps[value - (props.step.scale.startAtOne ? 1 : 0)]);
                            }}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            valueLabelDisplay="auto"
                            step={1}
                            min={props.step.scale.startAtOne ? 1 : 0}
                            max={props.step.scale.startAtOne ? props.step.scale.steps.length : props.step.scale.steps.length - 1}
                            marks={props.step.scale.steps.map((step, index) => {
                                return {
                                    "value": (props.step.scale.startAtOne ? 1 : 0) + index,
                                    "label": "" + ((props.step.scale.startAtOne ? 1 : 0) + index)
                                }
                            })}
                    />
                </Box>
                <Stack justifyContent="start" direction="row">
                    <Button variant="contained" disabled={value.length === 0} color="secondary"
                            startIcon={<SendIcon/>}
                            onClick={() => {
                                update(value);
                                setValue("");
                                props.goToNamedStep(props.step.scale.submit.goto)
                            }}>
                        {t(props.step.scale.submit.label)}
                    </Button>{speech(t(props.step.scale.submit.label))}
                </Stack>

            </>}
            {props.step.field && props.step.field.type !== "files" && props.step.field.type !== "phoneNumbersArray" &&
                <Grid component="div" container direction="row" justifyContent="space-between" alignItems="flex-end"
                      mt={3} spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField label={t(props.step.field.label)} value={value} fullWidth
                                   multiline={!!props.step.field.rows} rows={props.step.field.rows}
                                   type={props.step.field.type}
                                   inputProps={getInputProps(props)}
                                   onChange={(e) => setValue(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} sm={12} container direction="row" justifyContent="end" alignItems="flex-end">
                        <Stack justifyContent="start" direction="row" spacing={2}>

                            {props.step.field.submit.canBeSkipped &&
                                <><Button variant="contained" disabled={value.length > 0} color="secondary"
                                          startIcon={<SendIcon/>}
                                          onClick={() => {
                                              update(null);
                                              setValue("");
                                              props.goToNamedStep(props.step.field.submit.goto)
                                          }}>
                                    {t('skip')}
                                </Button>{speech(t('skip'))}</>}


                            <Button variant="contained" disabled={value.length === 0} color="secondary"
                                    startIcon={<SendIcon/>}
                                    onClick={() => {
                                        if (props.step.field.condition && props.step.field.condition.type === "MIN_LENGTH" &&
                                            value.length < props.step.field.condition.value) {
                                            props.goToNamedStep(props.step.field.condition.goto)
                                        } else {
                                            update(value);
                                            setValue("");
                                            if (props.step.field.submit.create) props.fetchData(props).then(() => props.goToNamedStep(props.step.field.submit.goto))
                                            else props.goToNamedStep(props.step.field.submit.goto)
                                        }
                                    }}>
                                {t(props.step.field.submit.label)}
                            </Button>{speech(t(props.step.field.submit.label))}
                        </Stack>
                    </Grid>

                </Grid>}
            {props.step.field && props.step.field.type === "files" && props.step.field.type !== "phoneNumbersArray" &&
                <Stack direction="row" alignItems="flex-end" justifyContent="end">
                    <UploadFile id={props.step.field.label}
                                key={props.step.field.label}
                                update={update}
                                meta={{
                                    "key": `files_${props.step.field.label}`,
                                    "fileType": "COMPLAINT_PICTURE_BY_COMPLAINANT",
                                    "code": props.code,
                                }} label={t(props.step.field.submit.label)}
                                callback={() => {
                                    props.goToNamedStep(props.step.field.submit.goto)
                                }}/>
                    {speech(t(props.step.field.submit.label))}
                </Stack>}
            {props.step.field && props.step.field.type === "phoneNumbersArray" &&
                <Grid component="div" container direction="row" justifyContent="space-between" alignItems="flex-end"
                      mt={3}
                      spacing={1}>
                    <Grid item xs={12} sm={9}>
                        {/* TODO https://github.com/goveo/react-international-phone/blob/master/src/stories/UiLibsExample/components/MuiPhone.tsx*/}
                        <PhoneInput defaultCountry={getCountry()}
                                    value={Array.isArray(value) ? value[value.length - 1] : value} hideDropdown={true}
                                    onChange={(value => setValue(value))}/>{speech(t(props.step.field.label))}
                    </Grid>
                    <Grid item xs={12} sm={3} container direction="row" justifyContent="end" alignItems="flex-end">
                        <Button variant="contained" disabled={value.length === 0} color="secondary"
                                startIcon={<SendIcon/>}
                                onClick={() => {
                                    update(value);
                                    setValue("");
                                    props.goToNamedStep(props.step.field.submit.goto)
                                }}>
                            {t(props.step.field.submit.label)}
                        </Button>{speech(t(props.step.field.submit.label))}
                    </Grid>

                </Grid>}
            {props.step.links &&
                <Stack direction="row" justifyContent="space-around" sx={{mt: 2}}>
                    {props.step.links.map(link => <Stack justifyContent="start" direction="row">
                        <Link href={t(link.url)} target={link.stayOnPage ? "_self" : "_blank"} key={link.label}>
                            <Button variant="contained" startIcon={startIcon(link.icon)}>
                                {t(link.label)}
                            </Button>
                        </Link>{speech(t(link.label))}</Stack>)}
                </Stack>}
        </CardContent>
        <CardActions sx={{
            display: "flex",
            flexDirection: flexDirection(),
            justifyContent: justifyButtons(),
            alignItems: "start",
            p: 0,
            m: 0,
            lineHeight: 1,
        }}>
            {props.step.buttons && !props.step.multipleSelect && !props.step.singleSelect
                && props.step.buttons.map(button =>
                    <Stack key={button.label} justifyContent="start" direction="row" ml={1} mt={1}>
                        <Button key={button.label} variant="contained"
                                startIcon={startIcon(button.icon)}
                                onClick={() => {
                                    if (button.update) props.update(button.update.field, button.update.value);
                                    props.goToNamedStep(button.goto)
                                }}>{t(button.label)}
                        </Button>
                        {speech(t(button.label))}
                    </Stack>)}
            {props.step.buttons && props.step.singleSelect && <SingleSelect props={props}/>}
            {props.step.buttons && props.step.multipleSelect && <MultipleSelect props={props}/>}
        </CardActions>

    </>;
}


// todo def in backend
