export const survey_0125 = [
    {
        stepName: "welcome",
        title: "welcome.title",
        text: "welcome.text",
        links: [
            {type: "link", url: "welcome.links.url", label: "welcome.links.label"}],
        buttons: [{label: "ok", goto: "age",}]
    }, {
        stepName: "age",
        previousStep: "welcome",
        title: "age.title",
        text: "age.text",
        multipleChoice: true,
        buttons: [
            {
                label: "age.buttons.below14",
                goto: "gender",
                update: {field: "age", value: "below15"}
            }, {
                label: "age.buttons.14to18",
                goto: "gender",
                update: {field: "age", value: "15to18"}
            }, {
                label: "age.buttons.19to24",
                goto: "gender",
                update: {field: "age", value: "19to24"}
            }, {
                label: "age.buttons.25to34",
                goto: "gender",
                update: {field: "age", value: "25to34"}
            }, {
                label: "age.buttons.35to44",
                goto: "gender",
                update: {field: "age", value: "35to44"}
            }, {
                label: "age.buttons.45to54",
                goto: "gender",
                update: {field: "age", value: "45to54"}
            }, {
                label: "age.buttons.55andAbove",
                goto: "gender",
                update: {field: "age", value: "55andAbove"}
            }
        ]

    }, {
        stepName: "gender",
        previousStep: "age",
        title: "gender.title",
        text: "gender.text",
        multipleChoice: true,
        buttons: [
            {
                label: "gender.buttons.male",
                goto: "timeInFactory",
                update: {field: "gender", value: "MALE"}
            },
            {
                label: "gender.buttons.female",
                goto: "timeInFactory",
                update: {field: "gender", value: "FEMALE"}
            },
            {
                label: "gender.buttons.preferNotToSay",
                goto: "timeInFactory",
                update: {field: "gender", value: "PREFER_NOT_TO_SAY"}
            },
            {
                label: "gender.buttons.diverse",
                goto: "timeInFactory",
                update: {field: "gender", value: "OTHER"}
            }]
    }, {
        stepName: "timeInFactory",
        previousStep: "gender",
        text: "timeInFactory.text",
        multipleChoice: true,
        buttons: [
            {
                label: "timeInFactory.buttons.lessThanOneYear",
                goto: "timeInIndustry",
                update: {field: "timeInFactory", value: "LESS_THAN_ONE_YEAR"}
            },
            {
                label: "timeInFactory.buttons.oneToFiveYears",
                goto: "timeInIndustry",
                update: {field: "timeInFactory", value: "ONE_TO_FIVE_YEARS"}
            },
            {
                label: "timeInFactory.buttons.sixToTenYears",
                goto: "timeInIndustry",
                update: {field: "timeInFactory", value: "SIX_TO_TEN_YEARS"}
            },
            {
                label: "timeInFactory.buttons.moreThanTenYears",
                goto: "timeInIndustry",
                update: {field: "timeInFactory", value: "MORE_THAN_TEN_YEARS"}
            },]
    }, {
        stepName: "timeInIndustry",
        previousStep: "timeInFactory",
        title: "timeInIndustry.title",
        text: "timeInIndustry.text",
        multipleChoice: true,
        buttons: [
            {
                label: "timeInIndustry.buttons.lessThanOneYear",
                goto: "employment",
                update: {field: "timeInIndustry", value: "LESS_THAN_ONE_YEAR"}
            },
            {
                label: "timeInIndustry.buttons.oneToFiveYears",
                goto: "employment",
                update: {field: "timeInIndustry", value: "ONE_TO_FIVE_YEARS"}
            },
            {
                label: "timeInIndustry.buttons.sixToTenYears",
                goto: "employment",
                update: {field: "timeInIndustry", value: "SIX_TO_TEN_YEARS"}
            },
            {
                label: "timeInIndustry.buttons.elevenToFifteenYears",
                goto: "employment",
                update: {field: "timeInIndustry", value: "ELEVEN_TO_FIFTEEN_YEARS"}
            },
            {
                label: "timeInIndustry.buttons.moreThanSixteenYears",
                goto: "employment",
                update: {field: "timeInIndustry", value: "MORE_THAN_SIXTEEN_YEARS"}
            },]
    }, {
        stepName: "employment",
        previousStep: "timeInIndustry",
        title: "employment.title",
        text: "employment.text",
        multipleChoice: true,
        buttons: [
            {label: "employment.buttons.fullTime", goto: "contract", update: {field: "employment", value: "FULL_TIME"}},
            {label: "employment.buttons.partTime", goto: "contract", update: {field: "employment", value: "PART_TIME"}},
            {
                label: "employment.buttons.dayLaborer",
                goto: "contract",
                update: {field: "employment", value: "DAY_LABORER"}
            },
            {
                label: "employment.buttons.temporary",
                goto: "contract",
                update: {field: "employment", value: "TEMPORARY"}
            },
            {
                label: "employment.buttons.preferNotToSay",
                goto: "contract",
                update: {field: "employment", value: "PREFER_NOT_TO_SAY"}
            },]
    }, {
        stepName: "contract",
        previousStep: "employment",
        title: "contract.title",
        text: "contract.text",
        buttons: [
            {label: "contract.buttons.yes", goto: "salary", update: {field: "contract", value: "YES"}},
            {label: "contract.buttons.no", goto: "salary", update: {field: "contract", value: "NO"}},
            {
                label: "contract.buttons.preferNotToSay",
                goto: "salary",
                update: {field: "contract", value: "PREFER_NOT_TO_SAY"}
            }
        ],
    }, {
        stepName: "salary",
        previousStep: "concern",
        title: "salary.title",
        text: "salary.text",
        multipleChoice: true,
        buttons: [
            {
                label: "salary.buttons.lessThan200K",
                goto: "workingTimes",
                update: {field: "salary", value: "LESS_THAN_200K"}
            },
            {label: "salary.buttons.around200K", goto: "workingTimes", update: {field: "salary", value: "AROUND_200K"}},
            {label: "salary.buttons.around250K", goto: "workingTimes", update: {field: "salary", value: "AROUND_250K"}},
            {label: "salary.buttons.around300K", goto: "workingTimes", update: {field: "salary", value: "AROUND_300K"}},
            {label: "salary.buttons.around350K", goto: "workingTimes", update: {field: "salary", value: "AROUND_350K"}},
            {
                label: "salary.buttons.moreThan350K",
                goto: "workingTimes",
                update: {field: "salary", value: "MORE_THAN_350K"}
            },
            {
                label: "salary.buttons.preferNotToSay",
                goto: "workingTimes",
                update: {field: "salary", value: "PREFER_NOT_TO_SAY"}
            }]
    }, {
        stepName: "workingTimes",
        previousStep: "salary",
        title: "workingTimes.title",
        text: "workingTimes.text",
        multipleChoice: true,
        buttons: [
            {
                label: "workingTimes.buttons.oneToFiveHours",
                goto: "breakTimeFrequency",
                update: {field: "workingTimes", value: "ONT_TO_FIVE_HOURS"}
            },
            {
                label: "workingTimes.buttons.sixToTenHours",
                goto: "breakTimeFrequency",
                update: {field: "workingTimes", value: "SIX_TO_TEN_HOURS"}
            },
            {
                label: "workingTimes.buttons.elevenToFifteenHours",
                goto: "breakTimeFrequency",
                update: {field: "workingTimes", value: "ELEVEN_TO_FIFTEEN_HOURS"}
            },
            {
                label: "workingTimes.buttons.sixteenToTwentyHours",
                goto: "breakTimeFrequency",
                update: {field: "workingTimes", value: "SIXTEEN_TO_TWENTY_HOURS"}
            },
            {
                label: "workingTimes.buttons.twentyOneToTwentyFourHours",
                goto: "breakTimeFrequency",
                update: {field: "workingTimes", value: "TWENTY_ONE_TO_TWENTY_FOUR_HOURS"}
            }]
    }, {
        stepName: "breakTimeFrequency",
        previousStep: "workingTimes",
        title: "breakTimeFrequency.title",
        text: "breakTimeFrequency.text",
        buttons: [
            {
                label: "breakTimeFrequency.buttons.oneToTwoTimes",
                goto: "breakTimeSatisfaction",
                update: {field: "breakTimeFrequency", value: "ONE_TO_TWO_TIMES"}
            },
            {
                label: "breakTimeFrequency.buttons.threeToFiveTimes",
                goto: "breakTimeSatisfaction",
                update: {field: "breakTimeFrequency", value: "THREE_TO_FIVE_TIMES"}
            },
            {
                label: "breakTimeFrequency.buttons.sixToEightTimes",
                goto: "breakTimeSatisfaction",
                update: {field: "breakTimeFrequency", value: "SIX_TO_EIGHT_TIMES"}
            },
            {
                label: "breakTimeFrequency.buttons.nineToTenTimes",
                goto: "breakTimeSatisfaction",
                update: {field: "breakTimeFrequency", value: "NINE_TO_TEN_TIMES"}
            },
            {
                label: "breakTimeFrequency.buttons.moreThanTenTimes",
                goto: "breakTimeSatisfaction",
                update: {field: "breakTimeFrequency", value: "MORE_THAN_TEN_TIMES"}
            }]

    }, {
        stepName: "breakTimeSatisfaction",
        previousStep: "breakTimeFrequency",
        title: "breakTimeSatisfaction.title",
        text: "breakTimeSatisfaction.text",
        buttons: [
            {
                label: "breakTimeSatisfaction.buttons.verySatisfied",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "VERY_SATISFIED"}
            },
            {
                label: "breakTimeSatisfaction.buttons.satisfied",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "SATISFIED"}
            },
            {
                label: "breakTimeSatisfaction.buttons.neutral",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "NEUTRAL"}
            },
            {
                label: "breakTimeSatisfaction.buttons.unsatisfied",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "UNSATISFIED"}
            },
            {
                label: "breakTimeSatisfaction.buttons.veryUnsatisfied",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "VERY_UNSATISFIED"}
            },
            {
                label: "breakTimeSatisfaction.buttons.preferNotToSay",
                goto: "sickLeaveExperience",
                update: {field: "breakTimeSatisfaction", value: "PREFER_NOT_TO_SAY"}
            }]

    }, {
        stepName: "sickLeaveExperience",
        previousStep: "breakTimeSatisfaction",
        text: "sickLeaveExperience.text",
        buttons: [
            {
                label: "sickLeaveExperience.buttons.wentToWork",
                goto: "sickLeaveComfortability",
                update: {field: "sickLeaveExperienceExplanation", value: "WENT_TO_WORK"}
            },
            {
                label: "sickLeaveExperience.buttons.stayedAtHome",
                goto: "sickLeaveComfortability",
                update: {field: "sickLeaveExperienceExplanation", value: "STAYED_AT_HOME"}
            },
            {
                label: "sickLeaveExperience.buttons.preferNotToSay",
                goto: "sickLeaveComfortability",
                update: {field: "sickLeaveExperienceExplanation", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "sickLeaveExperienceExplanation",
        previousStep: "sickLeaveExperience",
        title: "sickLeaveExperienceExplanation.title",
        text: "sickLeaveExperienceExplanation.text",
        field: {
            name: "sickLeaveExperienceExplanation",
            type: "text",
            label: "sickLeaveExperienceExplanation.field.label",
            submit: {
                label: "sickLeaveExperienceExplanation.field.submit.label",
                icon: "SendIcon",
                goto: "sickLeaveComfortability"
            }
        },
    },
    {
        stepName: "sickLeaveComfortability",
        previousStep: "sickLeaveExperience",
        title: "sickLeaveComfortability.title",
        text: "sickLeaveComfortability.text",
        buttons: [
            {
                label: "sickLeaveComfortability.buttons.always",
                goto: "pregnancy",
                update: {field: "sickLeaveComfortability", value: "ALWAYS"}
            },
            {
                label: "sickLeaveComfortability.buttons.sometimes",
                goto: "pregnancy",
                update: {field: "sickLeaveComfortability", value: "SOMETIMES"}
            },
            {
                label: "sickLeaveComfortability.buttons.rarely",
                goto: "pregnancy",
                update: {field: "sickLeaveComfortability", value: "RARELY"}
            },
            {
                label: "sickLeaveComfortability.buttons.never",
                goto: "pregnancy",
                update: {field: "sickLeaveComfortability", value: "NEVER"}
            },
            {
                label: "sickLeaveComfortability.buttons.preferNotToSay",
                goto: "pregnancy",
                update: {field: "sickLeaveComfortability", value: "PREFER_NOT_TO_SAY"}
            },]
    }, {
        stepName: "pregnancy",
        previousStep: "sickLeaveComfortability",
        title: "pregnancy.title",
        text: "pregnancy.text",
        buttons: [
            {label: "pregnancy.buttons.yes", goto: "pregnancyTasks", update: {field: "pregnancy", value: "YES"}},
            {label: "pregnancy.buttons.no", goto: "safetyFeeling", update: {field: "pregnancy", value: "NO"}},
            {
                label: "pregnancy.buttons.preferNotToSay",
                goto: "pregnancyTasks",
                update: {field: "pregnancy", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "pregnancyTasks",
        previousStep: "pregnancy",
        title: "pregnancyTasks.title",
        text: "pregnancyTasks.text",
        buttons: [
            {
                label: "pregnancyTasks.buttons.harderThanBefore",
                goto: "safetyFeeling",
                update: {field: "pregnancyTasks", value: "HARDER"}
            },
            {
                label: "pregnancyTasks.buttons.sameAsBefore",
                goto: "safetyFeeling",
                update: {field: "pregnancyTasks", value: "SAME"}
            },
            {
                label: "pregnancyTasks.buttons.easierThanBefore",
                goto: "safetyFeeling",
                update: {field: "pregnancyTasks", value: "EASIER"}
            },
            {
                label: "pregnancyTasks.buttons.preferNotToSay",
                goto: "safetyFeeling",
                update: {field: "pregnancyTasks", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "safetyFeeling",
        previousStep: "pregnancyTasks",
        title: "safetyFeeling.title",
        text: "safetyFeeling.text",
        scale: {
            name: "safetyFeeling",
            steps: ["safetyFeeling.scale.step_1", "safetyFeeling.scale.step_2", "safetyFeeling.scale.step_3", "safetyFeeling.scale.step_4", "safetyFeeling.scale.step_5"],
            submit: {
                label: "safetyFeeling.field.submit.label",
                icon: "SendIcon",
                goto: "safetyBuilding"
            }
        },
    },
    {
        stepName: "safetyBuilding",
        previousStep: "safetyFeeling",
        title: "safetyBuilding.title",
        text: "safetyBuilding.text",
        scale: {
            name: "safetyBuilding",
            steps: ["safetyBuilding.scale.step_1", "safetyBuilding.scale.step_2", "safetyBuilding.scale.step_3", "safetyBuilding.scale.step_4", "safetyBuilding.scale.step_5"],
            submit: {
                label: "safetyBuilding.field.submit.label",
                icon: "SendIcon",
                goto: "equipment"
            }
        },
    },
    {
        stepName: "equipment",
        previousStep: "safetyBuilding",
        title: "equipment.title",
        text: "equipment.text",
        buttons: [
            {label: "equipment.buttons.yes", goto: "hygiene", update: {field: "equipment", value: "YES"}},
            {label: "equipment.buttons.no", goto: "hygiene", update: {field: "equipment", value: "NO"}},
            {
                label: "equipment.buttons.preferNotToSay",
                goto: "hygiene",
                update: {field: "equipment", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "hygiene",
        previousStep: "equipment",
        title: "hygiene.title",
        text: "hygiene.text",
        scale: {
            name: "hygiene",
            steps: ["hygiene.scale.step_1", "hygiene.scale.step_2", "hygiene.scale.step_3", "hygiene.scale.step_4", "hygiene.scale.step_5"],
            submit: {
                label: "hygiene.field.submit.label",
                icon: "SendIcon",
                goto: "atmosphere"
            }
        },
    },
    {
        stepName: "atmosphere",
        previousStep: "hygiene",
        title: "atmosphere.title",
        text: "atmosphere.text",
        buttons: [
            {label: "atmosphere.buttons.positive", goto: "happiness", update: {field: "atmosphere", value: "POSITIVE"}},
            {label: "atmosphere.buttons.neutral", goto: "happiness", update: {field: "atmosphere", value: "NEUTRAL"}},
            {label: "atmosphere.buttons.negative", goto: "happiness", update: {field: "atmosphere", value: "NEGATIVE"}},
            {
                label: "atmosphere.buttons.preferNotToSay",
                goto: "happiness",
                update: {field: "atmosphere", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "happiness",
        previousStep: "atmosphere",
        title: "happiness.title",
        text: "happiness.text",
        scale: {
            name: "happiness",
            steps: ["happiness.scale.step_1", "happiness.scale.step_2", "happiness.scale.step_3", "happiness.scale.step_4", "happiness.scale.step_5"],
            submit: {
                label: "happiness.field.submit.label",
                icon: "SendIcon",
                goto: "equalTreatment"
            }
        },
    },
    {
        stepName: "equalTreatment",
        previousStep: "happiness",
        title: "equalTreatment.title",
        text: "equalTreatment.text",
        buttons: [
            {
                label: "equalTreatment.buttons.stronglyAgree",
                goto: "miscellaneous",
                update: {field: "equalTreatment", value: "STRONGLY_AGREE"}
            },
            {
                label: "equalTreatment.buttons.agree",
                goto: "miscellaneous",
                update: {field: "equalTreatment", value: "AGREE"}
            },
            {
                label: "equalTreatment.buttons.disagree",
                goto: "miscellaneous",
                update: {field: "equalTreatment", value: "DISAGREE"}
            },
            {
                label: "equalTreatment.buttons.stronglyDisagree",
                goto: "miscellaneous",
                update: {field: "equalTreatment", value: "STRONGLY_DISAGREE"}
            },
            {
                label: "equalTreatment.buttons.preferNotToSay",
                goto: "miscellaneous",
                update: {field: "equalTreatment", value: "PREFER_NOT_TO_SAY"}
            },]
    },
    {
        stepName: "miscellaneous",
        previousStep: "equalTreatment",
        title: "miscellaneous.title",
        text: "miscellaneous.text",
        field: {
            name: "miscellaneous",
            type: "text",
            label: "miscellaneous.field.label",
            submit: {
                label: "miscellaneous.field.submit.label",
                icon: "SendIcon",
                goto: "end"
            }
        },
        buttons: [{label: "skip", icon: "SkipNextIcon", goto: "end",},]
    },
    {
        stepName: "end",
        previousStep: "miscellaneous",
        title: "end.title",
        text: "end.text",
        links: [
            {type: "link", url: "end.links.url", label: "end.links.label", stayOnPage: true}],
    },

];
