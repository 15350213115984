import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import ComplaintWizard from "../components/wizard/ComplaintWizard";
import {checkInSteps} from "../components/wizard/steps/checkInSteps";
import {useCheckinMutation, useGetComplaintCodeQuery} from "../app/apiSlice";
import {useRouteMatch} from "react-router-dom";
import {deuter_0323} from "../components/wizard/steps/deuter_0323";
import {deuter_0524} from "../components/wizard/steps/deuter_0524";
import {deuter_0524_mm} from "../components/wizard/steps/deuter_0524_mm";
import {survey_0125} from "../components/wizard/steps/survey_0125";
import {new_talent_0125} from "../components/wizard/steps/new_talent_0125";
import {dong_sheng_0125} from "../components/wizard/steps/dong_sheng_0125";
import {baselineassessment_cdi_management} from "../components/wizard/steps/baselineassessment_cdi_management";
import {baselineassessment_cdi_worker} from "../components/wizard/steps/baselineassessment_cdi_worker";
import themex from "../components/theme";

const CheckIn = () => {

    const [steps, setSteps] = useState(null);
    const [brand, setBrand] = useState("demo");
    const match = useRouteMatch();


    useEffect(() => {

        switch (match.params.id) {
            case "survey_0125":
                setBrand("nbtop");
                setSteps(survey_0125);
                break;
            case "new_talent_0125":
            case "test_new_talent_0125":
                setBrand("nbtop");
                setSteps(new_talent_0125);
                break;
            case "dong_sheng_0125":
            case "test_dong_sheng_0125":
                setBrand("nbtop");
                setSteps(dong_sheng_0125);
                break;
            case "deuter_0323":
            case "test_deuter_0323":
            case "deuter_0423":
            case "test_deuter_0423":
                setSteps(deuter_0323);
                break;
            case "test_deuter_0524":
                setSteps(deuter_0524);
                break;
            case "test_deuter_0524_mm":
                setSteps(deuter_0524_mm);
                break;
            case "test_baselineassessment_cdi_management":
            case "baselineassessment_cdi_management":
                setSteps(baselineassessment_cdi_management);
                break;
            case "test_baselineassessment_cdi_worker":
            case "baselineassessment_cdi_worker":
                setSteps(baselineassessment_cdi_worker);
                break;
            default:
                setSteps(checkInSteps);
                break;
        }
    }, [match.params.id]);

    if (!steps) return null;

    return (
        <Grid container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{minHeight: '100vh',}}

        >

            <Grid item xs={12} id="complaintWizard"
                  sx={{minHeight: 350, mt: 2, width: "100%", maxWidth: "600px !important",}} padding={1}
                  bgcolor={"transparent"}>
                <ComplaintWizard steps={steps} brand={brand} name={`${match.params.id}_checkin`} theme={themex}
                                 translation={`${(match.params.id).replace("test_", "")}_checkin`}
                                 useUpdateMutation={useCheckinMutation}
                                 useGetCodeQuery={useGetComplaintCodeQuery} useCreateMutation={useCheckinMutation}/>
            </Grid>
        </Grid>
    );
};

export default CheckIn;
